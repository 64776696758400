import Vue from "vue";

/*TITLE*/
document.title = "RESIDENCIAL LA TORRE | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "RESIDENCIAL LA TORRE";
Vue.prototype.$subtitle = "El Puerto de Santa María, Cádiz";

/*INTRO*/
Vue.prototype.$promoter = "RESIDENCIAL LA TORRE";
Vue.prototype.$introSubtitle = "El Puerto de Santa María, Cádiz";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "0-livingroom--20221124091753.jpeg";
Vue.prototype.$image_kitchen = "0-kitchen--20221124091753.jpeg";
Vue.prototype.$image_bath1 = "0-bathroom1--20221124091753.jpeg";
Vue.prototype.$image_bath2 = "0-bathroom2--20221124091753.jpeg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "0-livingroom--20221124091753.jpeg",
  },
  {
    src: "1-livingroom--20221124091753.jpeg",
  },
  {
    src: "2-livingroom--20221124091753.jpeg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "0-kitchen--20221124091753.jpeg",
  },
  {
    src: "1-kitchen--20221124091753.jpeg",
  },
  {
    src: "2-kitchen--20221124091753.jpeg",
  },
  {
    src: "3-kitchen--20221124091753.jpeg",
  },
  {
    src: "4-kitchen--20221124091753.jpeg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "0-bathroom1--20221124091753.jpeg",
  },
  {
    src: "1-bathroom1--20221124091753.jpeg",
  },
  {
    src: "2-bathroom1--20221124091753.jpeg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "0-bathroom2--20221124091753.jpeg",
  },
  {
    src: "1-bathroom2--20221124091753.jpeg",
  },
  {
    src: "2-bathroom2--20221124091753.jpeg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "https://spaces.porcelanosa.com/tag/latorre/?e=iframe";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = false;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=av%20santa%20beatriz%20de%20silva%20el%20puerto%20de%20sta%20maría%20cádiz%2011500",
    text: "Av. Santa Beatriz de Silva, El Puerto de Sta María, Cádiz, 11500",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20sol%202%20el%20puerto%20de%20santa%20maría%20cádiz%2011500",
    text: "Calle Sol, 2,  El Puerto de Santa María, Cádiz, 11500",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:+34956874211",
    text: "+34956874211",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@estudioollero.es",
    text: "info@estudioollero.es",
  },
];
